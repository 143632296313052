<template>
  <!-- 编码:lzq -->
  <div>
    <main>
      <!-- 头部横幅 -->
      <div class="banner">
        <div class="banner1">
          <img
            v-lazy="require('../assets/imgs/way_recommend/banner.webp')"
            alt=""
          />
        </div>
        <!-- 横幅内容区域 -->
        <div class="banner2">
          <h2 class="title">路线推荐</h2>
          <p class="introduce">提供定位服务，助力游客出行</p>
        </div>
      </div>

      <!-- 方案优势讲解 -->
      <p class="title_h2">方案优势</p>
      <div class="advantage">
        <div class="advantage_item">
          <div class="image_box">
            <img src="../assets/imgs/way_recommend/gps.png" />
          </div>
          <div class="spn_box">
            <p>定位</p>
            <span>定位精确率业内领先，防止偏差</span>
          </div>
        </div>
        <div class="advantage_item">
          <div class="image_box">
            <img src="../assets/imgs/way_recommend/serves.png" />
          </div>
          <div class="spn_box">
            <p>路线服务</p>
            <span>规划路线，预估到达时间，路况实时更新</span>
          </div>
        </div>
        <div class="advantage_item">
          <div class="image_box">
            <img src="../assets/imgs/way_recommend/map.png" />
          </div>
          <div class="spn_box">
            <p>地图展示</p>
            <span>全平台展示，多条路线选择</span>
          </div>
        </div>
      </div>
      <!-- 聚类分析 -->
      <div class="analyze">
        <p>聚类分析</p>
        <span> 通过蚁群算法来优化旅游路线使旅游计划和路线达到最优 </span>
        <div>
          <img v-lazy="require('../assets/imgs/way_recommend/guand_up8.webp')" />
        </div>
      </div>
    </main>
    <!-- 头部组件 -->
    <Header></Header>
    <!-- 底部组件 -->
    <Footer></Footer>
  </div>
</template>
<script>
// @ is an alias to /src
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  name: "way_recommend",
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 600px;
  color: #fff;
  position: relative;
  .banner1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    // loading大小
    img[lazy="loading"] {
      width: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      width: 100%;
      height: 100%;
    }
  }
  .banner2 {
    width: 100%;
    height: 600px;
    position: absolute;
    top: 0;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 130%);
    z-index: 1;
    .title {
      z-index: 1;
      height: 67px;
      font-size: 48px;
      font-weight: 500;
      line-height: 67px;
      letter-spacing: 3px;
      text-align: left;
      position: absolute;
      left: 361px;
      top: 230px;
    }
    .introduce {
      z-index: 1;
      width: 565px;
      font-size: 24px;
      font-weight: 400;
      line-height: 33px;
      text-align: left;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      position: absolute;
      left: 361px;
      top: 321px;
    }
  }
}
.introduce,
.title {
  animation: sport 0.5s;
  transform: translateY(0px);
}
//设置头部文字动画效果
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

//版心
.title_h2 {
  font-size: 40px;
  font-weight: 500px;
  color: #333;
  letter-spacing: 1px;
  margin-bottom: 60px;
  margin-top: 80px;
}
.advantage {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  height: 150px;
  margin: auto;
  margin-bottom: 100px;
  .advantage_item {
    display: flex;
    width: 380px;
    height: 150px;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    .image_box {
      width: 64px;
      height: 64px;
      margin: 43px 24px 43px 42px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .spn_box {
      text-align: left;
      p {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        margin-top: 47px;
        margin-bottom: 8px;
      }
      span {
        display: inline-block;
        width: 200px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
.analyze {
  height: 780px;
  background: #f6f8fb;
  &::before {
    content: "";
    display: block;
    height: 0.1px;
  }
  p {
    height: 56px;
    margin-top: 80px;
    margin-bottom: 24px;
    font-size: 40px;
    font-weight: 500;
    color: #333333;
  }
  span {
    display: inline-block;
    font-size: 20px;
    font-weight: 400;
    color: #565656;
    letter-spacing: 1px;
    margin-bottom: 60px;
  }
  div {
    width: 1200px;
    height: 420px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    // loading大小
    img[lazy="loading"] {
      width: 150px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
